import React from "react"
import { useTranslation } from "datastory-shared/utils/i18n"
import { Container, TextSM, HeadingThree, Stack } from "datastory-shared/ui"

import { getPageProps } from "utils/ssg"

const Error404 = () => {
  const { t } = useTranslation()

  return (
    <Container
      maxW="container.sm"
      minHeight="50vh"
      textAlign="center"
      pt={["lg", "xl"]}
    >
      <Stack spacing="md">
        <HeadingThree as="h1">{"404"}</HeadingThree>

        <TextSM>{t("og_c:error__404")}</TextSM>
      </Stack>
    </Container>
  )
}

export const getStaticProps = async ({ locale: language }) =>
  await getPageProps({ language })

export default Error404
